<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row class="my-3">
      <v-col>
        <v-btn
          color="primary"
          @click.stop="createDialog()"
        >
          Crear Usuario
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
        offset-md="6"
        offset-lg="8"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar por Nombre o Correo"
          single-line
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          light
        >
          <template v-slot:[`item.status`]="{ item }">
            <span :class="item.status ? 'success--text' : 'error--text'">
              <b>
                {{ item.status ? "Activo" : "Inactivo" }}
              </b>
            </span>
          </template>

          <template v-slot:[`item.verified`]="{ item }">
            <span :class="item.verified ? 'success--text' : 'error--text'">
              <b>
                {{ item.verified ? "Verificado" : "Sin Verificar" }}
              </b>
            </span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              :color="item.status ? 'error' : 'success'"
              @click="setStatus(item.id, !item.status)"
            >
              {{ item.status ? "mdi-close-circle" : "mdi-check-circle" }}
            </v-icon>
            <v-icon @click="updateDialog(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogs.create"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="font-title">
          Crear Usuario
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="forms.create.name"
                label="Nombre"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="forms.create.email"
                label="Email"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="forms.create.height"
                label="Altura (cm)"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="forms.create.weight"
                label="Peso (lb)"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-password-field
                v-model="forms.create.password"
                label="Contraseña"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-password-field
                v-model="forms.create.confirmPassword"
                label="Confirmar Contraseña"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <date-picker
                v-model="forms.create.birthday"
                label="Fecha de nacimiento"
                type="birthday"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="forms.create.role"
                :items="roles"
                label="Rol"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p
                v-for="(error, i) in forms.create.errors"
                :key="`error-${i}`"
                class="error--text"
              >
                {{ error }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="create"
          >
            Crear
          </v-btn>
          <v-btn
            color="secondary"
            @click="dialogs.create = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogs.update"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="font-title">
          Actualizar Usuario
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="forms.update.name"
                label="Nombre"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="forms.update.email"
                label="Email"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="forms.update.height"
                label="Altura (cm)"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="forms.update.weight"
                label="Peso (lb)"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="forms.update.gender"
                :items="genders"
                label="Genero"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <date-picker
                v-model="forms.update.birthday"
                label="Fecha de nacimiento"
                type="birthday"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-password-field
                v-model="forms.update.password"
                label="Contraseña"
              />
              <template>
                <span
                  class="error--text"
                  :style="{fontSize:'10px'}"
                >
                  <b>
                    Si no desea modificar este campo, por favor déjelo vacío.
                  </b>
                </span>
              </template>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-password-field
                v-model="forms.update.confirmPassword"
                label="Confirmar Contraseña"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="forms.update.role"
                :items="roles"
                label="Rol"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p
                v-for="(error, i) in forms.update.errors"
                :key="`error-${i}`"
                class="error--text"
              >
                {{ error }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="update"
          >
            Actualizar
          </v-btn>
          <v-btn
            color="secondary"
            @click="dialogs.update = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Swal from 'sweetalert2'
  import { isEmpty, isEmail, isInt, equals } from 'validator'
  const Cookies = require('js.cookie')

  export default {
    data () {
      return {
        headers: [
          { text: 'Nombre', value: 'name' },
          { text: 'Correo', value: 'email' },
          { text: 'Rol', value: 'role.title' },
          { text: 'Estado', value: 'status' },
          { text: 'Verificacion', value: 'verified' },
          { text: 'Acciones', value: 'action' },
        ],
        items: [],
        roles: [],
        emailActual: null,
        genders: [
          { text: 'Masculino', value: 'M' },
          { text: 'Femenino', value: 'F' },
        ],
        search: null,
        dialogs: {
          create: false,
          update: false,
        },
        forms: {
          create: {
            name: '',
            birthday: '',
            height: '',
            weight: '',
            email: '',
            password: '',
            role: '',
            errors: [],
          },
          update: {
            id: null,
            name: '',
            birthday: '',
            height: '',
            weight: '',
            email: '',
            role: '',
            gender: '',
            errors: [],
            password: '',
            confirmPassword: '',
          },
        },
      }
    },
    async mounted () {
      await this.getItems()
      await this.getRoles()
    },
    methods: {
      async getItems () {
        this.items = await this.$api.User.list()
      },
      async getRoles () {
        const roles = await this.$api.Role.list()
        this.roles = roles.map(x => {
          return { text: x.title, value: x.id }
        })
      },
      validate (type) {
        let form = null
        if (type === 'create') {
          form = this.forms.create
          form.errors.splice(0, form.errors.length)
          if (isEmpty(form.name)) {
            form.errors.push('Debe ingresar el nombre del usuario')
          }
          if (!isEmail(form.email)) {
            form.errors.push('Debe ingresar un email valido')
          }
          if (!isInt(form.height)) {
            form.errors.push('La altura debe ser un numero entero')
          }
          if (!isInt(form.weight)) {
            form.errors.push('El peso debe ser un numero entero')
          }
          if (isEmpty(form.password)) {
            form.errors.push('Debe ingresar la contraseña')
          } else if (!equals(form.password, form.confirmPassword)) {
            form.errors.push('Las contraseñas ingresadas no coinciden')
          }
          if (isEmpty(form.birthday)) {
            form.errors.push('Debe ingresar la fecha de nacimiento')
          }
          if (isEmpty(`${form.role}`)) {
            form.errors.push('Debe seleccionar un rol para el usuario')
          }
        } else if (type === 'update') {
          form = this.forms.update
          form.errors.splice(0, form.errors.length)
          if (isEmpty(form.name)) {
            form.errors.push('Debe ingresar el nombre del usuario')
          }
          if (isEmpty(form.birthday)) {
            form.errors.push('Debe ingresar la fecha de nacimiento')
          }
          if (isEmpty(`${form.role}`)) {

          }
          if (!isEmpty(form.password)) {
            if (form.password !== form.confirmPassword) {
              form.errors.push('Las contraseñas ingresadas no coinciden')
            }
          }
        }
        return form?.errors?.length === 0
      },
      async create () {
        const valid = this.validate('create')
        if (!valid) {
          return
        }
        const form = this.forms.create
        const payload = {
          name: form.name,
          email: form.email,
          height: form.height,
          weight: form.weight,
          password: form.password,
          birthday: form.birthday,
          role_id: form.role,
          status: true,
        }
        try {
          const response = await this.$api.Auth.register(payload)
          this.items.push(response)
          this.dialogs.create = false
        } catch (err) {
          const response = err.response
          const data = response.data
          form.errors.splice(0, form.errors.length)
          if (response.status === 400) {
            if ('msg' in data && data.msg === 'Already exists') {
              form.errors.push('El correo ya esta registrado')
            }
          }
        }
      },
      createDialog () {
        const form = this.forms.create
        form.name = ''
        form.email = ''
        form.height = ''
        form.weight = ''
        form.password = ''
        form.confirmPassword = ''
        form.birthday = ''
        form.role = ''
        this.dialogs.create = true
      },
      async updateDialog (item) {
        const response = await this.$api.User.get(item.id)
        this.emailActual = response.email
        const form = this.forms.update
        form.id = response.id
        form.name = response.name
        form.birthday = response.birthday
        form.role = response.role ? response.role.id : null
        this.dialogs.update = true
        form.height = response.height
        form.weight = response.current_weight
          ? response.current_weight.weight
          : ''
        form.email = response.email
        form.gender = response.gender
        form.password = ''
        form.confirmPassword = ''
      },
      async update () {
        const valid = this.validate('update')
        if (!valid) {
          return
        }
        const form = this.forms.update
        const payload = {
          name: form.name,
          birthday: form.birthday === 'Invalid date' ? '1999-12-31' : form.birthday,
          role_id: form.role,
          email: form.email,
          gender: form.gender,
          height: form.height,
          weight: form.weight,
          admin: Cookies.get('userid'),
          password: form.password,
        }

        try {
          const response = await this.$api.User.update(form.id, payload)
          if (response.status === 204) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'El correo ya está siendo usado, intenta con otro!',
              showConfirmButton: false,
              timer: 2500,
            })
          } else if (response.status === 200) {
            if (response.data.msg && response.data.msg === 'Sin permiso') {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'No tienes permisos para actualizar la contraseña!',
                showConfirmButton: false,
                timer: 1500,
              })
            } else {
              const index = this.items.map(x => x.id).indexOf(form.id)
              this.items.splice(index, 1, response.data)
              this.dialogs.update = false
            }
          }
        } catch (err) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Ha ocurrido un error en el servidor!',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      },
      async setStatus (id, status) {
        const response = await this.$api.User.setStatus(id, status)
        const index = this.items.map(x => x.id).indexOf(id)
        this.items.splice(index, 1, response)
      },
    },
  }
</script>
